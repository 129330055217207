:root{
  --primary-color: #4A249D;
  --secondary-color: #009FBD;
  --cta-color: #F9E2AF;
}
:root::-webkit-scrollbar {
  width: 1em !important;
  height: 1em !important;
}

:root::-webkit-scrollbar-track {
  background: linear-gradient(rgba(91,88,88,0.11948529411764708) 0%, rgba(74,36,157,1) 100%) !important;
  /* border-radius: 100vw !important;
  margin-block: 0.5em !important; */
}

:root::-webkit-scrollbar-thumb {
  background: hsl(259, 63%, 38%, 0.5) !important;
  border: 0.125em solid hsl(259, 63%, 38%, 1) !important;
  border-radius: 100vw !important;
}

:root::-webkit-scrollbar-thumb:hover {
  background: hsl(259, 63%, 38%)(0%, 0%, 0%, 0.5) !important;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #F9E2AF !important;
  background: rgb(91,88,88);
  background: linear-gradient(180deg, rgba(91,88,88,0.11948529411764708) 0%, rgba(74,36,157,1) 25%);}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
.centered,h1,h2,h3,h4,h5,h6 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-main{
  min-height: 100vh !important;
}
.row:not(.row-main, footer .row, .row-form){
  min-height: calc(100vh - 56px);
  padding-top: 56px;
}
footer {
  min-height: 30vh !important;
  background: var(--primary-color);
}
.modal-signup{
  background: var(--primary-color) !important;
}
.btn{
  background: var(--secondary-color) !important;
}
.nav-link{
  border-radius: 5px;
  transition: all 0.3s ease-in;
}
.nav-link:hover{
  background: var(--secondary-color);
  color: var(--cta-color);
}
.bg-transparent{
  background: transparent !important;
}
.row-overlay {
  position: relative;
  z-index: 1 !important;
}
.navibar{
  position: fixed !important;
  z-index: 2 !important;
  width: 100% !important;
}
.row-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(91,88,88);
  background: radial-gradient(circle at center right, rgba(91,88,88,0.11948529411764708) 0%, rgba(74,36,157,1) 100%);
  z-index: -1; /* Ensure the overlay is behind the content */
}
li{
  font-size: 20px;
  font-weight: 600;
}





















@media (min-width: 768px) {
  .hero,h1{
    font-size: calc(4.375rem + 1.5vw) !important;
  }
}
@media (min-width: 340px) {
  .hero{
    font-size: 3rem;
  }
  h2{
    font-size: 1.5rem;
  }
}
